/**
 * All code that communicates with the backend server API should
 * pass through here. It will handle auth and other stuff.
 */

import socketIOClient from 'socket.io-client';
import EventEmitter from 'events';

const defaultOptions = {
  queueHost: 'https://realtime.dutchtelco.nl/queue',
  blfHost: 'https://realtime.dutchtelco.nl/blf',
};

class Api extends EventEmitter {
  constructor(overrideOptions) {
    super();
    const options = { ...defaultOptions, ...overrideOptions };

    this.queues = [];
    this.connectedQ = false;
    this.onlineQ = false;
    this.connectedB = false;
    this.onlineB = false;
    this.started = false;

    const storedSession = localStorage.getItem('wallboardSession');
    if (storedSession) {
      this.session = JSON.parse(storedSession);
    } else {
      this.session = { };
    }

    this.connectionTimer = setInterval(() => {
      if (this.connectedB && !this.onlineB) {
        this.tokenAuth();
        console.log("BLF not online");
      }
      else if (this.connectedQ && !this.onlineQ) {
        this.tokenAuth();
        console.log("Queue not online");
      }
    }, 5000);

    this.blfSocket = socketIOClient(options.blfHost);
    this.blfSocket.on('connect', () => {
      this.connectedB = true;
      console.log('Connected');
      if (this.started && !this.onlineB) {
        this.tokenAuth();
      }
    });
    this.blfSocket.on('disconnect', () => {
      console.log('Disconnect');
      this.onlineB = false;
      this.connectedB = false;
    });
    this.blfSocket.on('fetch_room_status', (statuses) => {
      console.log('fetch_room_status', statuses);
    });
    this.blfSocket.on('call_ringing', (statuses) => {
      console.log('call_ringing', statuses);
    });
    this.blfSocket.on('call_setup', (statuses) => {
      console.log('call_setup', statuses);
    });
    this.blfSocket.on('call_connected', (statuses) => {
      console.log('call_connected', statuses);
    });
    this.blfSocket.on('call_disconnected', (statuses) => {
      console.log('call_disconnected', statuses);
    });
    this.queueSocket = socketIOClient(options.queueHost);
    this.queueSocket.on('connect', () => {
      console.log('Connected');
      this.connectedQ = true;
      if (this.started && !this.onlineQ) {
        this.tokenAuth();
      }
    });
    this.queueSocket.on('disconnect', () => {
      this.onlineQ = false;
      this.connectedQ = false;
      console.log('Disconnected');
    });
    /*
    this.queueSocket.on('queue_report', (statuses) => {
      console.log('queue_report', statuses);
    });
    this.queueSocket.on('queue_left', (statuses) => {
      console.log('queue_left', statuses);
    });
    this.queueSocket.on('queue_call_completed', (statuses) => {
      console.log('queue_call_completed', statuses);
    });
    this.queueSocket.on('queue_abandon', (statuses) => {
      console.log('queue_abandon', statuses);
    });
    */
  }

  login(username, password) {
    this.session.username = username;
    this.session.password = password;
  }

  logout() {
    this.session = { };
    localStorage.removeItem('wallboardSession');
  }

  tokenAuth() {
    return new Promise((resolve, reject) => {
      fetch('https://realtime.dutchtelco.nl/users/v1/authenticate/key', {
        method: 'GET',
        mode: 'cors',
        headers: {
          accept: 'application/json, text/plain, */*',
          'accept-language': 'en-US,en;q=0.9',
          'Api-Username': this.session.username,
          'Api-Password': this.session.password,
          'content-type': 'application/x-www-form-urlencoded',
        },
        body: null,
      }).then((response) => response.json()).then((response) => {
        if (response.status !== 'OK') reject(response);
        this.started = true;
        this.queues = [];
        const { rooms } = response.data[0];
        let room;
        for (var i in rooms) {
          const key = i;
          room = rooms[key];
          if (room.room_type.type === 'queue') {
            this.queues.push(room);
          }
        }
        this.emit('ready', this);
        localStorage.setItem('wallboardSession', JSON.stringify(this.session));

        const data = {
          username: this.session.username,
          key: response.data[0].key,
        };
        if (this.connectedB && !this.onlineB) {
          this.blfSocket.emit('join.rooms', data, (response) => {
            console.log('blf join', response);
            if (response !== true) reject();
            else this.onlineB = true;
            resolve(response);
          });
        }
        if (this.connectedB && !this.onlineB) {
          this.queueSocket.emit('join.rooms', data, (response) => {
            console.log('queue join', response);
            if (response !== true) reject();
            else this.onlineQ = true;
            resolve(response);
          });
        }
        resolve(response);
      });
    });
  }
  getContacts() {
    return new Promise((resolve, reject) => {
      fetch('https://realtime.dutchtelco.nl/users/v1/contacts', {
        method: 'GET',
        mode: 'cors',
        headers: {
          accept: 'application/json, text/plain, */*',
          'accept-language': 'en-US,en;q=0.9',
          'Api-Username': this.session.username,
          'Api-Password': this.session.password,
          'content-type': 'application/x-www-form-urlencoded',
        },
        body: null,
      }).then((response) => response.json()).then((response) => {
        if (response.status !== 'OK') reject(response);
        resolve(response.data);
      });
    });
  }
}

export default Api;
