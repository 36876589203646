import { addLocaleData } from 'react-intl';

const localeDatas = {
  en: () => import('react-intl/locale-data/en' /* webpackChunkName: 'locale-data-en' */),
  nl: () => import('react-intl/locale-data/nl' /* webpackChunkName: 'locale-data-nl' */),
};

const loadTranslation = (locale) => import(`../translations/${locale}.json` /* webpackChunkName: 'translations-[request]' */);

const initializeWithLocale = (locale) => new Promise((resolve) => {
  const lang = locale.substr(0, 2).toLowerCase();
  localeDatas[lang]().then(({ default: localeData }) => {
    addLocaleData(localeData);
    loadTranslation(locale).then((messages) => {
      resolve(messages);
    });
  });
});

export { initializeWithLocale }; // eslint-disable-line import/prefer-default-export
