import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      open: false,
      anchorEl: null,
      loginError: null,
    };
  }

  handleClick = (event) => {
    const { username, password } = this.state;
    const { api, history } = this.props;
    this.setState({ anchorEl: event.currentTarget });

    console.log(username, password);
    api.login(username, password);
    api.tokenAuth().then((response) => {
      history.push('/');
    }).catch((e) => {
      this.setState({ open: true, loginError: e.data });
    });
  }

  handleInput = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  render() {
    const {
      username,
      password,
      open,
      anchorEl,
      loginError,
    } = this.state;
    return (
      <div className="App">
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item>
            <Card>
              <CardContent>
                <TextField
                  name="username"
                  label="Username"
                  onChange={this.handleInput}
                  value={username}
                />
                <br />
                <TextField
                  name="password"
                  type="password"
                  label="Password"
                  value={password}
                  onChange={this.handleInput}
                />
                <Button variant="contained" onClick={(event) => this.handleClick(event)}>Login</Button>
                <Popover
                  open={open}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Typography>{loginError}</Typography>
                </Popover>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

Login.propTypes = {
  intl: intlShape.isRequired,
  api: PropTypes.object.isRequired,
};

export default withRouter(injectIntl(Login));
