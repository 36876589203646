import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';
import Moment from 'react-moment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import UserChip from './UserChip';
import { withStyles } from '@material-ui/styles';

const styles = {
  red: {
    color: 'red',
    fontWeight: 'bold',
  },
};

class Queue extends Component {
    interval: any;

    constructor(props, context) {
      super(props, context);
      const { queue } = this.props;
      this.extension = `${queue.customer_id}_${queue.identifier}`;

      this.state = {
        userExtentions: [],
        activeCalls: {},
        historyCalls: [],
        chart: [],
        blf: [],
        queueCount: 0,
        callCount: 0,
        onCallCount: 0,
        avrCallTime: 0,
        queueAgents: 0,
        queueActive: 0,
        queueAbandoned: 0,
        queueCalls: 0,
        queueCompleted: 0,
        queueHoldtime: 0,
        queueServiceLevel: 0,
        queueServiceLevelPerf: 0,
        queueStrategy: '',
        queueTalktime: 0,
        queueMax: 0,

      };
    }

    UNSAFE_componentWillMount() {
      const { api } = this.props;
      api.queueSocket.on('queue_add', (statuses) => {
        if (statuses.extension !== this.extension) return;
        console.log('queue_add', statuses);
        this.setState((prevstate) => {
          const count = prevstate.queueCount + 1;
          const { activeCalls } = prevstate;
          statuses.inQueue = true;
          statuses.start = new Date();
          statuses.stop = 0;
          statuses.icon = 'local_phone';
          activeCalls[statuses.id] = statuses;
          return { queueCount: count, activeCalls };
        });
      });
      api.queueSocket.on('queue_report', (statuses) => {
        if (statuses.extension !== this.extension) return;
        console.log('queue_report', statuses);
        const { agents } = statuses;
        this.setState((prevstate) => {
          const { userExtentions } = prevstate;
          let agentCount = 0;
          let activeCalls = 0;
          let activeMembers = [];
          for (var i in agents) {
            if (agents[i].active === 1) {
              const extention = agents[i].identifier.split('_')[1];
              if (userExtentions[extention] === undefined) {
                userExtentions[extention] = {
                  extention,
                  name: agents[i].name,
                  state: 'terminated',
                  color: 'default',
                  icon: 'call_end',
                };
              }
              agentCount++;
              activeMembers.push(extention);
            }
          }
          let oldActiveMembers = Object.keys(userExtentions);
          let removeMembers = oldActiveMembers 
                 .filter(x => !activeMembers.includes(x))
                 .concat(activeMembers.filter(x => !oldActiveMembers.includes(x)));
          for (var exMember of removeMembers) {
            delete(userExtentions[exMember]);
          }

          return {
            queueAgents: agentCount,
            queueAbandoned: statuses.abandoned,
            queueActive: activeCalls,
            queueCalls: statuses.calls,
            queueCompleted: statuses.completed,
            queueHoldtime: statuses.holdtime,
            queueServiceLevel: statuses.servicelevel,
            queueServiceLevelPerf: statuses.servicelevelperf,
            queueStrategy: statuses.strategy,
            queueTalktime: statuses.talktime,
            queueMax: statuses.max,
            userExtentions,
          };
        });
      });
      api.queueSocket.on('queue_abandon', (statuses) => {
        if (statuses.extension !== this.extension) return;
        console.log('queue_abandon', statuses);
        this.updateQueue(statuses, 'abandon');
      });
      api.queueSocket.on('queue_left', (statuses) => {
        if (statuses.extension !== this.extension) return;
        console.log('queue_left', statuses);
        this.updateQueue(statuses, 'left');
      });
      api.queueSocket.on('queue_remove', (statuses) => {
        if (statuses.extension !== this.extension) return;
        console.log('queue_remove', statuses);
        this.updateQueue(statuses, 'removed');
      });
      api.queueSocket.on('agent_login', (statuses) => {
        if (statuses.extension !== this.extension) return;
        console.log('agent_login', statuses);
        this.setState((prevstate) => {
          const { userExtentions } = prevstate;
          const extention = statuses.extension.split('_')[1];
          if (userExtentions[extention] === undefined) {
            userExtentions[extention] = {
              extention,
              name: statuses.agent.name,
              state: 'terminated',
              color: 'default',
              icon: 'call_end',
            };
          }
        });
      });
      api.queueSocket.on('agent_logout', (statuses) => {
        if (statuses.extension !== this.extension) return;
        console.log('agent_logout', statuses);
      });
      api.blfSocket.on('fetch_room_status', (statuses) => {
        for (var i in statuses) {
          this.updateUsers(statuses[i]);
        }
      });
      api.blfSocket.on('call_ringing', (statuses) => {
        this.updateUsers(statuses);
      });
      api.blfSocket.on('call_setup', (statuses) => {
        this.updateUsers(statuses);
      });
      api.blfSocket.on('call_connected', (statuses) => {
        this.updateUsers(statuses);
      });
      api.blfSocket.on('call_disconnected', (statuses) => {
        this.updateUsers(statuses);
      });
    }

    updateQueue(call, removeType) {
      this.setState((prevstate) => {
        const { activeCalls } = prevstate;
        if (!activeCalls[call.id]) return {};
        let { callCount } = prevstate;
        let { avrCallTime } = prevstate;
        let { onCallCount } = prevstate;
        callCount++;
        avrCallTime += (new Date().getTime() - activeCalls[call.id].start.getTime());
        call.inQueue = false;
        call.start = activeCalls[call.id].start;
        call.stop = new Date();
        call.removeType = removeType;
        if (removeType === 'abandon') call.icon = 'call_missed';
        else if (removeType === 'left') call.icon = 'call_made';
        else if (removeType === 'removed') call.icon = 'call_made';
        if (removeType === 'removed') onCallCount++;
        let count = prevstate.queueCount;
        if (count > 0) count--;
        //if (callCount === 0) onCallCount = 0;
        if (activeCalls[call.id]) {
          delete activeCalls[call.id];
          var historyCalls = [
            call,
            ...prevstate.historyCalls,
          ];
        }
        return {
          queueCount: count,
          activeCalls,
          historyCalls,
          avrCallTime,
          callCount,
          onCallCount,
        };
      });
    }

    updateUsers(call) {
      this.setState((prevstate) => {
        const { userExtentions, historyCalls, queueActive, queueCompleted } = prevstate;
        let { onCallCount } = prevstate;
        const extention = call.called_user_extension;
        let icon = 'phone_in_talk';
        let color = 'secondary';
        let start = false;
        let callID = false;
        let outbound = false;
        if (userExtentions[extention] !== undefined) {
          start = userExtentions[extention].start;
          outbound = userExtentions[extention].outbound;
        }
        if (call.call_status === "connected") {
          start = new Date();
          callID = call.call_id;
        }
        if (call.call_status === 'terminated') {
          icon = 'call_end';
          color = 'default';
          start = false;
          callID = false;
        } else if (call.call_status === 'early') {
          icon = 'ring_volume';
          color = 'primary';
          outbound = true;
        } else if (call.call_status === 'ringing') {
          icon = 'ring_volume';
          color = 'primary';
        }

        var newActiveCalls = queueActive;
        var newQueueCompleted = queueCompleted;
        var calledQueue = false;
        //call['jsts'] = new Date(call.call_timestamp);
        call['jsts'] = new Date();

        //Find the call based on the caller id and the time it was in the queue
        const queueCallIdx = historyCalls.findIndex(x => {
          if (x.call_info.caller === call.caller_phonenumber) {
            const start = x.start.getTime() / 1000 | 0;
            const stop = (x.stop.getTime() / 1000 | 0) + 10;
            const now = call.jsts.getTime() / 1000 | 0;
            if (start <= now && stop >= now) {
              return true;
            } 
          }
          return false;
        });

        //If call is found bind it to the queue call
        if (queueCallIdx !== -1) {
          const data = historyCalls[queueCallIdx];
          if (data.removeType === "removed" || data.removeType === "left") {
            if (call.call_status === "connected") {
              historyCalls[queueCallIdx]['callID'] = call.call_id
              historyCalls[queueCallIdx]['blf'] = call;
              calledQueue = this.props.queue.identifier;
            }
          }
        }

        //When the call is stopped fix the queue
        const queueCallIdxT = historyCalls.findIndex(x => (x.callID === call.call_id));
        if (queueCallIdxT !== -1) {
          const data = historyCalls[queueCallIdxT];
            if (call.call_status === "terminated" && call.call_id === data.callID) {
              historyCalls[queueCallIdxT]['icon'] = "call_end"; 
              historyCalls[queueCallIdxT]['hangup'] = new Date();
              if (onCallCount > 0) onCallCount--;
              if (queueActive > 0) {
                newActiveCalls--; 
                newQueueCompleted++;
              }
            }
        }

        if (userExtentions[extention] !== undefined) {
          const oldUserExtention = userExtentions[extention];
          userExtentions[extention] = {
            name: oldUserExtention.name,
            extention,
            state: call.call_status,
            number: (call.caller_phonenumber?call.caller_phonenumber:call.called_phonenumber),
            queue: calledQueue,
            outbound,
            callID,
            color,
            icon,
            start,
          };
        }

        //No calls from the queue reset onCallCount
        const onCallSearch = userExtentions.findIndex(x => {
          if (x !== undefined) {
            if (x.icon !== undefined) {
              if (x.icon !== 'call_end')
                return true;
            }
          }
          return false;
        });
        if (onCallSearch === -1) {
          onCallCount = 0;
        }
        return {
          onCallCount,
          historyCalls: historyCalls,
          userExtentions: userExtentions,
          queueActive: newActiveCalls,
          queueCompleted: newQueueCompleted,
        };
      });
    }

    componentDidMount() {
      // Request current queue status here

      this.setState((prevstate) => {
        const chart = [...prevstate.chart,
          {
            key: new Date(),
            data: prevstate.queueCount,
          }];
        if (chart.length > 5) chart.shift();
        return { chart };
      });
      this.interval = setInterval(() => {
        this.setState((prevstate) => {
          const chart = [...prevstate.chart,
            {
              key: new Date(),
              data: prevstate.queueCount,
            }];
          if (chart.length > 5) chart.shift();
          const { historyCalls } = prevstate;
          if (historyCalls.length > 5) historyCalls.pop();
          return { chart, historyCalls };
        });
      }, 30 * 1000);
    }

    componentWillUnmount() {
      clearInterval(this.interval);
    }

    checkCounters(activeCalls,maxCalls,agents) {
      if (activeCalls===0 && maxCalls===0 && agents===0) return false;
      if (activeCalls > agents) return true;
      if (activeCalls >= maxCalls) return true;
    }

    render() {
      const {
        queue,
        classes,
      } = this.props;
      

      const {
        queueCompleted,
        queueAbandoned,
        queueActive,
        queueCalls,
        queueMax,
        queueCount,
        activeCalls,
        historyCalls,
        userExtentions,
        onCallCount,
      } = this.state;
      const agents = Object.keys(userExtentions).length;
      return (
        <Grid item>
          <Card raised>
            <CardContent>
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <h2>
                    {queue.display_name}
                    {' '}
(
                    {queue.identifier}
)
                  </h2>
                </Grid>
                <Grid item>
                  <Card>
                    <CardContent>
                      <Grid container direction="row">
                        <Grid item xs={3}>
                          <List>
                            <ListItem>
                              <ListItemText
                                primary="Completed"
                                secondaryTypographyProps={{ variant: 'h3' }}
                                secondary={queueCompleted}
                              />
                            </ListItem>
                          </List>
                        </Grid>
                        <Grid item xs={3}>
                          <List>
                            <ListItem>
                              <ListItemText
                                primary="Abandoned"
                                secondaryTypographyProps={{ variant: 'h3' }}
                                secondary={queueAbandoned}
                              />
                            </ListItem>
                          </List>
                        </Grid>
                        <Grid item xs={3}>
                          <List>
                            <ListItem>
                              <ListItemText
                                primary="On Call"
                                secondaryTypographyProps={{ variant: 'h2' }}
                                secondary={onCallCount}
                              />
                            </ListItem>
                          </List>
                        </Grid>
                        <Grid item xs={3}>
                          <List>
                            <ListItem>
                              <ListItemText
                                primary="Waiting"
                                classes={{ secondary: (this.checkCounters(Number(queueCalls),Number(queueMax),Number(agents))) ? classes.red : undefined }}
                                secondaryTypographyProps={{ variant: 'h2' }}
                                secondary={queueCount}
                              />
                            </ListItem>
                          </List>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item>
                  <Card>
                    <CardHeader
                      title="Callers"
                    />
                    <CardContent>
                      <Table padding="none" size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell />
                            <TableCell align="right">Caller</TableCell>
                            <TableCell align="right">Time</TableCell>
                            <TableCell align="right">Waiting</TableCell>
                            <TableCell align="right">Spoken</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Object.keys(activeCalls).map((key) => (
                            <TableRow key={key}>
                              <TableCell><Icon>local_phone</Icon></TableCell>
                              <TableCell align="right">{activeCalls[key].call_info.caller}</TableCell>
                              <TableCell align="right"><Moment format="H:mm">{activeCalls[key].start.getTime()}</Moment></TableCell>
                              <TableCell align="right"><Moment format="m:ss">{new Date().getTime() - activeCalls[key].start.getTime()}</Moment></TableCell>
                              <TableCell align="right" />
                            </TableRow>
                          ))}
                          {historyCalls.map((row) => (
                            <TableRow key={row.id}>
                              <TableCell><Icon>{row.icon}</Icon></TableCell>
                              <TableCell align="right">{row.call_info.caller}<br />{row.blf && `-> ${row.blf.called_user_extension}` }</TableCell>
                              <TableCell align="right"><Moment format="H:mm">{row.start}</Moment></TableCell>
                              <TableCell align="right">
                                <Moment format="m:ss">{row.stop.getTime() - row.start.getTime()}</Moment>
                              </TableCell>
                              <TableCell align="right">
                                {row.hangup &&
                                <Moment format="m:ss">{row.hangup.getTime() - row.stop.getTime()}</Moment>
                                }
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item>
                  <Card>
                    <CardHeader title="Agents" />
                    <CardContent>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell />
                            <TableCell align="right">Caller</TableCell>
                            <TableCell align="right" colSpan={2}>Time</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {userExtentions.length === 0
                            && <TableRow><TableCell><CircularProgress /></TableCell></TableRow>}
                          {userExtentions.map((row) => (
                            <TableRow key={row.extention}>
                              <TableCell>
                                <UserChip user={row} key={row.call_id} />
                              </TableCell>
                              <TableCell align="right" />
                              <TableCell align="right">{row.outbound?"->":"<-"} {row.number}</TableCell>
                              <TableCell align="right">{row.start && <Moment format="mm:ss">{new Date() - row.start}</Moment> }</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      );
    }
}

Queue.propTypes = {
  intl: intlShape,
  queue: PropTypes.object,
  api: PropTypes.object,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(injectIntl(Queue)));
