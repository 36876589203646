import React from 'react';
import ReactDOM from 'react-dom';
import { Route, HashRouter as Router, Switch } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import qs from 'query-string';
import './index.css';
import 'font-awesome/css/font-awesome.min.css';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { initializeWithLocale } from './utils/LocaleUtils';
import Api from './utils/Api';
import MainLayout from './layouts/Main';
import Dashboard from './views/Dashboard';
import Login from './views/Login';

import * as serviceWorker from './serviceWorker';


let { locale } = qs.parse(window.location.search);

locale = locale || 'en-US';

const api = new Api();

const theme = createMuiTheme({
  palette: {
    primary: { main: '#dd4b39' },
  },
});

initializeWithLocale(locale).then((messages) => {
  ReactDOM.render(
    <IntlProvider locale={locale} messages={messages}>
      <MuiThemeProvider theme={theme}>
        <Router>
          <MainLayout api={api}>
            <Switch>
              <Route exact path="/login" api={api} render={(props) => <Login {...props} api={api} />} />
              <Route api={api} render={(props) => <Dashboard {...props} api={api} />} />
            </Switch>
          </MainLayout>
        </Router>
      </MuiThemeProvider>
    </IntlProvider>, document.getElementById('root'),
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
