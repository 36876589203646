import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import { injectIntl, intlShape } from 'react-intl';
import { withRouter } from 'react-router-dom';

import {
  createMuiTheme,
} from '@material-ui/core';

const drawerWidth = 240;

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  callsignButton: {
    marginRight: '10px',
  },
  launch: {
    height: '14px',
    marginLeft: '-2px',
  },
});

class Main extends Component {
  get theme() {
    return createMuiTheme({
      palette: {
        type: ('dark'),
        primary: { main: '#dd4b39' },
      },
      typography: {
        useNextVariants: true,
      },
    });
  }
  render() {
    const { classes } = this.props;

    return (
      <MuiThemeProvider theme={this.theme}>
      <div className={classes.root}>
        <CssBaseline />
        <main className={classes.content}>
          { this.props.children }
        </main>
      </div>
      </MuiThemeProvider>
    );
  }
}

Main.propTypes = {
  intl: intlShape,
  api: PropTypes.object,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withMobileDialog()(withStyles(styles, { withTheme: true })(withRouter(injectIntl(Main))));
