import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Queue from '../components/Queue';
import BLF from '../components/BLF';

class Dashboard extends Component {
  constructor() {
    super();

    this.state = {
      date: new Date(),
    };
  }

  componentDidMount() {
    const { api, history } = this.props;
    if (api.session.username === undefined) history.push('/login');
    this.startTime();
    if (!api.onlineQ) {
      api.tokenAuth().catch((e) => {
        console.log(e);
        history.push('/login');
      });
    } 
    api.on('ready', () => {
      this.forceUpdate();
    });
  }

  UNSAFE_componentWillUnmount() {
    clearInterval(this.timer);
  }

  handleLogout = () => {
    const { api, history } = this.props;
    api.logout();
    history.push('/login');
  }

  startTime() {
    this.timer = setInterval(() => {
      this.setState(() => ({ date: new Date() }));
    }, 1000);
  }

  render() {
    const { api } = this.props;
    const { date } = this.state;
    return (
      <div>
        <Table>
          <TableBody>
            <TableRow key="row1">
              <TableCell>
                <Grid container direction="row" spacing={1}>
                  {api.queues.map((queue) => (
                    <Grid item key={queue.identifier}>
                      <Queue api={api} queue={queue} />
                    </Grid>
                  ))}
                </Grid>
              </TableCell>
              <TableCell align="right" style={{ width: '400px', verticalAlign: 'top' }}>
                <Card raised style={{ textAlign: 'center' }}>
                  <CardContent>
                    <List style={{ textAlign: 'center' }}>
                      <ListItem key="time">
                        <ListItemText
                          primary={`${date.toLocaleTimeString()}`}
                          primaryTypographyProps={{ variant: 'h2' }}
                        />
                      </ListItem>
                      <ListItem key="date">
                        <ListItemText
                          primary={date.toLocaleDateString()}
                          primaryTypographyProps={{ variant: 'h4' }}
                        />
                      </ListItem>
                    </List>
                    <Button onClick={this.handleLogout}>Logout</Button>
                    <Divider variant="middle" />
                    <br />
                    <BLF api={api} />
                  </CardContent>
                </Card>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  }
}

Dashboard.propTypes = {
  intl: intlShape.isRequired,
  api: PropTypes.object.isRequired,
};

export default withRouter(injectIntl(Dashboard));
