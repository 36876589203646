import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { withRouter } from 'react-router-dom';
import UserChip from './UserChip';

class BLF extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      blf: [],
    };
  }

  UNSAFE_componentWillMount() {
    const { api } = this.props;
    api.blfSocket.on('fetch_room_status', (statuses) => {
      for (var i in statuses) {
        this.updateBLF(statuses[i]);
      }
    });
    api.blfSocket.on('call_ringing', (statuses) => {
      this.updateBLF(statuses);
    });
    api.blfSocket.on('call_setup', (statuses) => {
      this.updateBLF(statuses);
    });
    api.blfSocket.on('call_connected', (statuses) => {
      this.updateBLF(statuses);
    });
    api.blfSocket.on('call_disconnected', (statuses) => {
      this.updateBLF(statuses);
    });
  }

  updateBLF(call) {
    this.setState((prevstate) => {
      const { blf } = prevstate;
      const callId = call.call_id;

      if (!call.call_id) return { blf };

      if (call.call_status === 'connected') {
        call.icon = 'phone_in_talk';
        call.color = 'secondary';
      } else if (call.call_status === 'early') {
        call.icon = 'ring_volume';
        call.color = 'primary';
      } else if (call.call_status === 'ringing') {
        call.icon = 'ring_volume';
        call.color = 'primary';
      }
      const callIdx = blf.findIndex(x => x.call_id === callId);
      if (call.call_status === 'terminated') {
        blf.splice(callIdx, 1);
        return { blf };
      }
      if (callIdx > -1) {
        blf[callIdx] = call;
      } else {
        blf.push(call);
      }
      return { blf };
    });
  }

  render() {
    const { blf } = this.state;
    return (
      <div>
        {blf.map((row) => (
          <UserChip user={row} key={row.call_id} />
        ))}
      </div>
    );
  }
}

BLF.propTypes = {
  intl: intlShape,
  api: PropTypes.object.isRequired,
};

export default withRouter(injectIntl(BLF));
