import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import styled from 'styled-components';

const StyledLabel = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
`;

const UserChip = ({
  user
}) => {
  let iconBackground={};
  if (!user.extention)
    user.extention = user.called_user_extension
  if (user.icon === "ring_volume") {
    iconBackground = { backgroundColor: '#f50057', color: '#FFFFFF' }
    user.color = "default"
  } else {
    iconBackground = { backgroundColor: '#bdbdbd' }
  }

  if (user['name'] !== undefined) {
    const nameSliced = user.name.split(" ");
    var name = nameSliced[0].slice(0,4);
    const regexp = /^[A-Z]/;
    for (var i = 1; i < nameSliced.length; i++) {
      if (regexp.test(nameSliced[i].slice(0,1))) {
        name = name + ' ' + nameSliced[i].slice(0,1);
      }
    }
  }
    
  return (<Chip
    avatar={<Avatar style={iconBackground}><Icon style={{ width: '24px', height: '24px' }}>{user.icon}</Icon></Avatar>}
    label={<StyledLabel>{(user.name?name:user.extention)}</StyledLabel>}
    color={user.color}
    key={user.call_id}
    />)
}

UserChip.propTypes = {
  user: PropTypes.object.isRequired,
};

export default UserChip;
